module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-use-shopping-cart/gatsby-browser.js'),
      options: {"plugins":[],"mode":"payment","cartMode":"client-only","stripePublicKey":"pk_live_51PxwmO09wzbjUb3t531yEiA2YGMq72wmbLFa2Mh6fBEEHerlkZf29qQxVINSoJmK69WyxV24ynz7dk8L9OoWq5q300oPReU8b8","successUrl":"https://www.google.com","cancelUrl":"https://www.stripe.com","currency":"GBP","allowedCountries":["GB"],"billingAddressCollection":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
